import React from 'react';
import styled from 'styled-components';
import ContactForm from './components/ContactForm';
import { FaWhatsapp, FaTelegram, FaPhone } from 'react-icons/fa';

const SectionTitle = styled.h2`
  font-size: 48px;
  color: #1E293B;
  text-align: left;
  margin: 120px 0 60px;
  font-weight: 800;
  line-height: 1.2;
  
  @media (max-width: 768px) {
    font-size: 36px;
    margin: 80px 0 40px;
  }
`;

const ContactTitle = styled.h2`
  font-size: 48px;
  color: #1E293B;
  font-weight: 800;
  margin-bottom: 10px;
  line-height: 1.2;
  
  @media (max-width: 768px) {
    font-size: 36px;
    margin-bottom: 40px;
  }
`;

function App() {
  return (
    <>
      <HeaderWrapper>
        <Header>
          <LogoText>ПОТОК</LogoText>
          <HeaderButton onClick={() => document.getElementById('contactForm').scrollIntoView({ behavior: 'smooth' })}>
            Оставить заявку
          </HeaderButton>
        </Header>
      </HeaderWrapper>
      <Container>
        <Main>
          <HeroSection>
            <HeroContent>
              <Title>Денежные переводы<br/>из России в Китай</Title>
              <Subtitle>Быстрые и надежные переводы на банковский счет в китайских юанях через ВТБ Шанхай</Subtitle>
              <HeroButton onClick={() => document.getElementById('contactForm').scrollIntoView({ behavior: 'smooth' })}>
                Оставить заявку
              </HeroButton>
            </HeroContent>
            <HeroImageContainer>
              <HeroImage src="/china-payment.png" alt="Переводы в Китай" />
            </HeroImageContainer>
          </HeroSection>

          <SectionTitle>Как это работает</SectionTitle>
          <Steps>
            <Step>
              <StepNumber>01</StepNumber>
              <StepTitle>Оставьте заявку</StepTitle>
              <StepText>Заполните форму или свяжитесь с нами любым удобным способом</StepText>
            </Step>
            <Step>
              <StepNumber>02</StepNumber>
              <StepTitle>Обсуждение деталей</StepTitle>
              <StepText>Согласуем сумму, сроки и условия перевода</StepText>
            </Step>
            <Step>
              <StepNumber>03</StepNumber>
              <StepTitle>Перевод средств</StepTitle>
              <StepText>Безопасно переводим деньги через ВТБ Шанхай</StepText>
            </Step>
          </Steps>

          <SectionTitle>Почему выбирают нас</SectionTitle>

          <Features>
            <Feature>
              <FeatureTitle>Надёжность</FeatureTitle>
              <FeatureText>
                Гарантируем стабильные финансовые потоки для вашего бизнеса
              </FeatureText>
            </Feature>
            <Feature>
              <FeatureTitle>Прозрачность</FeatureTitle>
              <FeatureText>
                Полный отчёт и информация о движении средств по каждой операции
              </FeatureText>
            </Feature>
            <Feature>
              <FeatureTitle>Пунктуальность</FeatureTitle>
              <FeatureText>
                Чёткое соблюдение сроков поступления средств вашим китайским партнёрам
              </FeatureText>
            </Feature>
          </Features>

          <SectionTitle>Наши преимущества</SectionTitle>

          <Advantages>
            <AdvantageCard>
              <Value>3-4 дня</Value>
              <Description>Срок поступления средств</Description>
            </AdvantageCard>
            <AdvantageCard>
              <Value>от 3.5%</Value>
              <Description>Комиссии за перевод</Description>
            </AdvantageCard>
            <AdvantageCard>
              <Value>100%</Value>
              <Description>Гарантия проведения платежа</Description>
            </AdvantageCard>
          </Advantages>



          <ContactSection id="contact">
            <ContactWrapper>
              <ContactGroup>
                <ContactTitle>Свяжитесь с нами</ContactTitle>
                <ContactInfo>
                  <ContactPhone href="tel:+79169720356">
                    +7 (916) 972-03-56
                  </ContactPhone>
                  <ContactName>Дмитрий</ContactName>
                  <ContactIcons>
                    <IconLink href="tel:+79169720356">
                      <FaPhone size={20} />
                    </IconLink>
                    <IconLink href="https://wa.me/79169720356">
                      <FaWhatsapp size={28} />
                    </IconLink>
                    <IconLink href="https://t.me/+79169720356">
                      <FaTelegram size={28} />
                    </IconLink>
                  </ContactIcons>
                </ContactInfo>
                
                <ContactInfo>
                  <ContactPhone href="tel:+79167770035">
                    +7 (916) 777-00-35
                  </ContactPhone>
                  <ContactName>Дмитрий</ContactName>
                  <ContactIcons>
                    <IconLink href="tel:+79167770035">
                      <FaPhone size={20} />
                    </IconLink>
                    <IconLink href="https://wa.me/79167770035">
                      <FaWhatsapp size={28} />
                    </IconLink>
                    <IconLink href="https://t.me/+79167770035">
                      <FaTelegram size={28} />
                    </IconLink>
                  </ContactIcons>
                </ContactInfo>
              </ContactGroup>
              
              <FormWrapper id="contactForm">
                <ContactForm />
              </FormWrapper>
            </ContactWrapper>
          </ContactSection>
        </Main>
      </Container>
    </>
  );
}

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  z-index: 100;
  width: 100%;
  padding-top: env(safe-area-inset-top);
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: env(safe-area-inset-top);
    background: white;
    z-index: 101;
  }

  @media (max-width: 768px) {
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.8) 100%
    );
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
`;

const LogoText = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 32px;
  font-weight: 800;
  color: #000;
  letter-spacing: 2px;
  
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const HeaderButton = styled.button`
  background: #2563eb;
  color: white;
  padding: 14px 28px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  transition: background 0.2s;

  &:hover {
    background: #1d4ed8;
  }
  
  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 14px;
  }
`;

const Main = styled.main`
  padding: 60px 0;
  margin-top: 80px;
`;

const HeroSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 60px;
  padding: 80px 0;
  
  @media (max-width: 968px) {
    padding: 40px 0;
    gap: 40px;
    flex-direction: column;
  }
`;

const HeroContent = styled.div`
  flex: 1;
`;

const HeroImageContainer = styled.div`
  flex: 1;
  max-width: 600px;
`;

const HeroImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

const HeroButton = styled.button`
  background: #0052FF;
  color: white;
  padding: 16px 32px;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 32px;
  transition: all 0.2s;

  &:hover {
    background: #0043cc;
    transform: translateY(-2px);
  }
`;

const Title = styled.h1`
  font-size: 56px;
  color: #1E293B;
  margin-bottom: 32px;
  font-weight: 800;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 40px;
    margin-bottom: 24px;
  }
`;

const Subtitle = styled.p`
  font-size: 22px;
  color: #64748B;
  max-width: 600px;
  margin-bottom: 40px;
  line-height: 1.6;
  white-space: normal;

  @media (max-width: 768px) {
    font-size: 18px;
    margin: 0 auto 32px;
    line-height: 1.5;
  }
`;

const Advantages = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-bottom: 60px;
`;

const AdvantageCard = styled.div`
  padding: 24px;
  background: #f8fafc;
  border-radius: 8px;
  text-align: center;
  transition: all 0.3s ease;
  border: 1px solid transparent;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    border-color: #2563eb;
  }
`;

const Value = styled.div`
  font-size: 36px;
  font-weight: bold;
  color: #2563eb;
  margin-bottom: 8px;
  position: relative;
  display: inline-block;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #2563eb;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  ${AdvantageCard}:hover & {
    &:after {
      transform: scaleX(1);
    }
  }
`;

const Description = styled.div`
  color: #64748b;
`;

const Features = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-bottom: 60px;
`;

const Feature = styled.div`
  padding: 24px;
  background: #f8fafc;
  border-radius: 8px;
  transition: all 0.3s ease;
  border: 1px solid transparent;

  &:hover {
    background: white;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    border-color: #2563eb;
  }
`;

const FeatureTitle = styled.h3`
  font-size: 24px;
  color: #1e293b;
  margin-bottom: 16px;
`;

const FeatureText = styled.p`
  color: #64748b;
  line-height: 1.6;
`;

const Divider = styled.div`
  height: 1px;
  background: linear-gradient(to right, transparent, #e2e8f0, transparent);
  margin: 60px 0;
`;

const ContactSection = styled.section`
  padding: 60px 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const ContactWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  align-items: stretch;
  
  @media (max-width: 968px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`;

const ContactGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-right: 60px;
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: -40px;
    bottom: -40px;
    width: 1px;
    background: #E2E8F0;
  }
  
  @media (max-width: 968px) {
    padding-right: 0;
    padding-bottom: 40px;
    gap: 30px;
    
    &:after {
      display: none;
    }
    
    border-bottom: 1px solid #E2E8F0;
  }
`;

const FormWrapper = styled.div`
  width: 100%;
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  margin-top: 0;
`;

const ContactPhone = styled.a`
  font-size: 32px;
  color: #1E293B;
  text-decoration: none;
  font-weight: 600;
  
  &:hover {
    color: #2563eb;
  }
`;

const ContactName = styled.div`
  font-size: 18px;
  color: #64748B;
`;

const ContactIcons = styled.div`
  display: flex;
  gap: 16px;
`;

const IconLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 1px solid #E2E8F0;
  transition: all 0.2s;

  // Стили для телефона
  &[href^="tel:"] {
    color: #1E293B;
  }

  // Стили для WhatsApp
  &[href^="https://wa.me"] {
    color: #25D366;
    border-color: #25D366;
  }

  // Стили для Telegram
  &[href^="https://t.me"] {
    color: #0088cc;
    border-color: #0088cc;
  }

  &:hover {
    transform: translateY(-2px);
  }
`;

const Steps = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin: 40px 0;
`;

const Step = styled.div`
  padding: 24px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
`;

const StepNumber = styled.div`
  font-size: 48px;
  font-weight: 800;
  color: #2563eb;
  opacity: 0.2;
  margin-bottom: 16px;
`;

const StepTitle = styled.h3`
  font-size: 24px;
  color: #1E293B;
  margin-bottom: 12px;
`;

const StepText = styled.p`
  color: #64748B;
  line-height: 1.6;
`;

export default App;
